import React, { useEffect, useState } from 'react';
import { NavLink, Link, useLocation } from 'react-router-dom';

const DesktopMenu = () => {
    const [isInLocation, setIsInLocation] = useState(false);
    const location = useLocation()
    const oversightLocation = () => {
        if (location.pathname === "/servicios/recursos-humanos" || location.pathname === "/servicios/consultoria-medica" || location.pathname === "/servicios/higiene-y-seguridad") {
            setIsInLocation(true)
        } else {
            setIsInLocation(false)
        }

    }
    useEffect(() => {
        oversightLocation()
    }, [location]);
    return (
        <>
            <div className="navbarLogoContainer">
                <Link to="/"><img src="/images/logo.png" alt="S&A Seguros" /></Link>
            </div>
            <ul className='navigationContainer'>
                <div className='mainLiContainer'>
                    <NavLink to="/" className={({ isActive }) => isActive ? "activeNavLink" : ""}><li className='mainLi'>NOSOTROS</li></NavLink>
                </div>
                <div className='mainLiContainer'>
                    <NavLink to="/seguros" style={{ overflow: 'visible' }} className={({ isActive }) => isActive ? "activeNavLink" : ""}><li className='mainLi' >SEGUROS</li></NavLink>
                    <ul className='dropdownContainer segurosDropdownContainer'>
                        <Link to="/seguros#personas"><li>PERSONAS</li></Link>
                        <Link to="/seguros#empresas"><li>EMPRESAS</li></Link>
                        <Link to="/seguros#art"><li>ART</li></Link>
                    </ul>
                </div>
                {/* <div className='mainLiContainer'>
                    <NavLink to="/art" className={({ isActive }) => isActive ? "activeNavLink" : ""}><li className='mainLi'>ART</li></NavLink>
                </div> */}

                <div className='mainLiContainer'>
                    <NavLink style={{ overflow: 'visible' }} className={() => isInLocation ? "activeNavLink" : ""}><li className='mainLi' >SERVICIOS</li></NavLink>
                    <ul className='dropdownContainer'>
                        <Link to="/servicios/recursos-humanos"><li>RECURSOS HUMANOS</li></Link>
                        <Link to="/servicios/higiene-y-seguridad"><li>HIGIENE Y SEGURIDAD</li></Link>
                        <Link to="/servicios/consultoria-medica"><li>CONSULTORÍA MÉDICA</li></Link>
                    </ul>
                </div>
                <div className='mainLiContainer'>
                    <NavLink to="/contacto" className={({ isActive }) => isActive ? "activeNavLink" : ""}><li className='mainLi'>CONTACTO</li></NavLink>
                </div>
                <div className='mainLiContainer'>
                    <NavLink to="/novedades" className={({ isActive }) => isActive ? "activeNavLink" : ""}><li className='mainLi'>NOVEDADES</li></NavLink>
                </div>
                <div className='mainLiContainer'>
                    <NavLink to="/productores" className={({ isActive }) => isActive ? "activeNavLink" : ""}><li className='mainLi'>PRODUCTORES</li></NavLink>
                </div>
                <a href="https://clientes.sya-seguros.com.ar" className="clientesButtonRedirect">
                    <li className='mainLi'>CLIENTES</li>
                </a>
            </ul>
        </>
    );
}

export default DesktopMenu;
