import { Link } from 'react-router-dom';
import "./TopDataSection.scss";
const TopDataSection = () => {
    return (
        <div className='topDataSection'>
            <Link to="mailto:contacto@sya-seguros.com.ar"><i className="bi bi-envelope-fill topDataSectionMobile"></i><p>contacto@sya-seguros.com.ar</p></Link>
            <div className="topDataSectionDesktop">
                <a href="tel:+5491121612198"><i className="bi bi-telephone-fill"></i><p>11 2161 2198</p></a>
                <a href="mailto:contacto@sya-seguros.com.ar"><i className="bi bi-envelope-fill"></i><p>contacto@sya-seguros.com.ar</p></a>
                <a target="_blank" href="https://www.instagram.com/sya_seguros/"><i className="bi bi-instagram"></i><p>@sya_seguros</p></a>
                <a target="_blank" href="https://www.facebook.com/profile.php?id=100094506547321"><i className="bi bi-facebook"></i><p>S&A Seguros</p></a>
            </div>
        </div>
    );
}

export default TopDataSection;
