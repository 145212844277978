import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import "../Contacto/Contacto.scss";

export const Productores = () => {
  const [formData, setFormData] = useState({})

  const navigate = useNavigate();

  const sendForm = () => {
    if (!formData.firstName || !formData.lastName || !formData.email || !formData.phone || !formData.cp) return alert('Por favor complete todos los campos')
    fetch(`${process.env.REACT_APP_BACKEND_URL}/wh/landing-productores-form`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...formData
      })
    }).then(res => res.json()).then(data => {
      if (data.success) {
        navigate('/emailSent')
      }
    })
      .catch(err => console.log(err))
  }

  const handleOnChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  return (
    <main className='contactSectionContainer'>
      <section className='contactContainer'>
        <div className='formContactContainer'>
          <h1>PRODUCTORES</h1>
          <h3>¡Te invitamos a formar parte de nuestro Broker!</h3>
          <h4 style={{ margin: '1rem' }}>Te ofrecemos:</h4>
          <ul style={{ textAlign: "start", margin: '0 1rem' }}>
            <li style={{ marginBottom: '0.5rem' }}>Acompañamiento comercial en las gestiones de tus clientes.</li>
            <li style={{ marginBottom: '0.5rem' }}>Asesoramiento técnico de nuestro personal especializado.</li>
            <li style={{ marginBottom: '0.5rem' }}>Acceso a las mejores condiciones comerciales y costos.</li>
            <li style={{ marginBottom: '0.5rem' }}>Gestión y seguimiento de siniestros.</li>
            <li style={{ marginBottom: '0.5rem' }}>Asesoría legal para tus clientes.</li>
            <li style={{ marginBottom: '0.5rem' }}>Desarrollo de tu cartera.</li>
            <li style={{ marginBottom: '0.5rem' }}>Capacitación de riesgos, coberturas y nuevos productos.</li>
            <li style={{ marginBottom: '0.5rem' }}>Las compañías número uno del mercado.</li>
            <li style={{ marginBottom: '0.5rem' }}>Más de 10 compañías para ofrecer a tus clientes.</li>
            <li style={{ marginBottom: '0.5rem' }}>Ganar tus negocios por servicio, costo y profesionalismo.</li>
            <li style={{ marginBottom: '0.5rem' }}>Servicios integrales para empresas que formen parte de tu cartera.</li>
          </ul>
          <p style={{ margin: '1rem' }}>
            <b>
              Si querés saber más sobre nosotros, completa los siguientes datos y nos pondremos en contacto a la brevedad.
            </b>
          </p>
          <div className='form'>
            <label>Nombre/s</label>
            <input required type="text" name='firstName' onChange={handleOnChange} />
            <label>Apellido/s</label>
            <input required type="text" name='lastName' onChange={handleOnChange} />
            <label>Email</label>
            <input required type="email" name='email' onChange={handleOnChange} />
            <label>Teléfono</label>
            <input required type="number" name='phone' onChange={handleOnChange} />
            <label>C.P.</label>
            <input required type="text" name='cp' onChange={handleOnChange} />
            <button onClick={sendForm}>ENVIAR</button>
          </div>
        </div>
      </section>
    </main>
  )
}
