import Footer from './components/Footer/Footer';
import Navbar from './components/Navbar/Navbar';
import "./Main.scss";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Nosotros from './pages/Nosotros/Nosotros';
import Contacto from './pages/Contacto/Contacto';
import Seguros from './pages/Seguros/Seguros';
import EmailSent from './pages/EmailSent';
import NotFound from './pages/NotFound';
import FloatingIcons from './components/FloatingIcons/FloatingIcons';
import ConsultoriaMedica from './pages/Servicios/ConsultoriaMedica';
import HigieneYSeguridad from './pages/Servicios/HigieneYSeguridad';
import RecursosHumanos from './pages/Servicios/RecursosHumanos';
import Novedades from './pages/Novedades/Novedades';
import { Productores } from './pages/Productores/Productores';
function App() {
  return (
    <>

      <BrowserRouter>
        <Navbar />

        <Routes>
          <Route path="/" element={<Nosotros />} />
          <Route path="/riesgos" />
          <Route path="/art" />
          <Route path="/seguros" element={<Seguros />} />
          <Route path="/servicios/consultoria-medica" element={<ConsultoriaMedica/>}/>
          <Route path="/servicios/higiene-y-seguridad" element={<HigieneYSeguridad/>}/>
          <Route path="/servicios/recursos-humanos" element={<RecursosHumanos/>}/>

          <Route path="/contacto" element={<Contacto />} />  {/*Form para info*/}
          <Route path="/novedades" element={<Novedades />} /> 
          <Route path="/productores" element={<Productores />} /> 
          <Route path="/emailSent" element={<EmailSent />} />
          <Route path="*" element={<NotFound/>}/>
        </Routes>
        <FloatingIcons/>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
