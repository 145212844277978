import { React, useState, useEffect } from 'react';
import { NavLink, Link, useLocation } from 'react-router-dom';
import "./MobileMenu.scss";
const MobileMenu = () => {
    const [mobileMenuOpened, setMobileMenuOpened] = useState(false);
    const changeMobileMenuState = () => {
        setMobileMenuOpened(!mobileMenuOpened)
    }
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
    });
    useEffect(() => {
        window.onresize = () => {
            setWindowSize({
                width: window.innerWidth,
            });
        };
        setTimeout(() => {
            if (windowSize.width < 670) {
                const mobile = document.getElementById("mobile")
                if (mobile) {

                    mobile.style.opacity = 1
                    const topLine = document.getElementById("topLine")
                    const middleLine = document.getElementById("middleLine")
                    const bottomLine = document.getElementById("bottomLine")
                    topLine.classList.add("topLineToDefault")
                    middleLine.classList.add("middleLineToDefault")
                    bottomLine.classList.add("bottomLineToDefault")
                } else {
                    setTimeout(() => {
                        mobile.style.opacity = 1
                        const topLine = document.getElementById("topLine")
                        const middleLine = document.getElementById("middleLine")
                        const bottomLine = document.getElementById("bottomLine")
                        topLine.classList.add("topLineToDefault")
                        middleLine.classList.add("middleLineToDefault")
                        bottomLine.classList.add("bottomLineToDefault")
                    }, 500);
                }
            }
        }, 1000);

    }, []);
    const [isInLocation, setIsInLocation] = useState(false);
    const activateNavLink = (location) => {


        setIsInLocation(location)


    }
    return (
        <>
            <div className={mobileMenuOpened ? "mobileMenu mobileMenuOpened" : "mobileMenu"} onClick={() => changeMobileMenuState()}>
                <div id="topLine" className='topLine'></div>
                <div id="middleLine" className='middleLine'></div>
                <div id="bottomLine" className='bottomLine'></div>
            </div>
            <div>
                <div id="mobile" style={{ opacity: 0 }} className={mobileMenuOpened ? 'showMobileMenuContainer mobileMenuContainer' : "mobileMenuContainer"}>
                    <ul>
                    <div className='mainLiContainer' style={{marginBottom: "15px", marginTop: "-10px"}}>
                            <a href="https://clientes.sya-seguros.com.ar" className="clientesButtonMobile"><li className='mainLi'>CLIENTES</li></a>
                        </div>
                        <div className='mainLiContainer'>
                            <NavLink onClick={() => { changeMobileMenuState(); window.scrollTo(0, 0) }} to="/" className={({ isActive }) => isActive ? "activeNavLink" : ""}><li className='mainLi'>NOSOTROS</li></NavLink>
                        </div>
                        <div className='mainLiContainer'>
                            <NavLink onClick={() => { changeMobileMenuState(); window.scrollTo(0, 0) }} to="/seguros" style={{ overflow: 'visible' }} className={({ isActive }) => isActive ? "activeNavLink" : ""}><li className='mainLi' >SEGUROS</li></NavLink>
                            <ul className='dropdownContainer'>
                                <Link onClick={() => changeMobileMenuState()} to="/seguros#personas"><li>PERSONAS</li></Link>
                                <Link onClick={() => changeMobileMenuState()} to="/seguros#empresas"><li>EMPRESAS</li></Link>
                                <Link onClick={() => changeMobileMenuState()} to="/seguros#art"><li>ART</li></Link>
                            </ul>
                        </div>
                        {/* <div className='mainLiContainer'>

                            <NavLink onClick={() => { changeMobileMenuState(); window.scrollTo(0, 0) }} to="/art" className={({ isActive }) => isActive ? "activeNavLink" : ""}><li className='mainLi'>ART</li></NavLink>
                        </div> */}
                        <div className='mainLiContainer'>
                            <Link onClick={() => { changeMobileMenuState(); window.scrollTo(0, 0) }} style={{ overflow: 'visible' }}><li className='mainLi' >SERVICIOS</li></Link>
                            <ul className='dropdownContainer'>
                                <NavLink onClick={() => { activateNavLink("/servicios/recursos-humanos"); changeMobileMenuState() }} className={() => isInLocation === "/servicios/recursos-humanos" ? "activeNavLink" : ""} to="/servicios/recursos-humanos"><li>RECURSOS HUMANOS</li></NavLink>
                                <NavLink onClick={() => { activateNavLink("/servicios/higiene-y-seguridad"); changeMobileMenuState() }} className={() => isInLocation === "/servicios/higiene-y-seguridad" ? "activeNavLink" : ""} to="/servicios/higiene-y-seguridad"><li>HIGIENE Y SEGURIDAD</li></NavLink>
                                <NavLink onClick={() => { activateNavLink("/servicios/consultoria-medica"); changeMobileMenuState() }} className={() => isInLocation === "/servicios/consultoria-medica" ? "activeNavLink" : ""} to="/servicios/consultoria-medica"><li>CONSULTORÍA MÉDICA</li></NavLink>
                            </ul>
                        </div>
                        <div className='mainLiContainer'>
                            <NavLink onClick={() => { changeMobileMenuState(); window.scrollTo(0, 0) }} to="/contacto" className={({ isActive }) => isActive ? "activeNavLink" : ""}><li className='mainLi'>CONTACTO</li></NavLink>
                        </div>
                        <div className='mainLiContainer'>
                            <NavLink onClick={() => { changeMobileMenuState(); window.scrollTo(0, 0) }} to="/novedades" className={({ isActive }) => isActive ? "activeNavLink" : ""}><li className='mainLi'>NOVEDADES</li></NavLink>
                        </div>
                        <div className='mainLiContainer'>
                            <NavLink onClick={() => { changeMobileMenuState(); window.scrollTo(0, 0) }} to="/productores" className={({ isActive }) => isActive ? "activeNavLink" : ""}><li className='mainLi'>PRODUCTORES</li></NavLink>
                        </div>
                    </ul>
                </div>
            </div>
        </>
    );
}

export default MobileMenu;
