import React from 'react';
import { Link } from 'react-router-dom';
import "./Footer.scss";
const Footer = () => {
    return (
        <>
            <footer>
                <section className='footerContent'>
                    <div>
                        <img src="/images/logo.png" alt="S&A Seguros" />
                    </div>
                    <div className='footerContactInfo'>
                        <ul>
                            <li>
                                <a href="tel:+5491121612198"><i className="bi bi-telephone-fill"></i>11 2161 2198</a>
                            </li>
                            <li>
                                <a href="mailto:contacto@sya-seguros.com.ar"><i className="bi bi-envelope-fill"></i>contacto@sya-seguros.com.ar</a>
                            </li>
                            <li>
                                <a target="_blank" href="https://www.instagram.com/sya_seguros/"><i className="bi bi-instagram"></i>@sya_seguros</a>
                            </li>
                            <li>
                                <a target="_blank" href="https://www.facebook.com/profile.php?id=100094506547321"><i className="bi bi-facebook"></i>S&A Seguros</a>
                            </li>
                        </ul>
                    </div>
                    <p>Matricula PAS 100470 COSTANZO ANABELLA</p>
                </section>
                <article className='informacionRegulatoria'>
                    <p><Link to="https://www.argentina.gob.ar/superintendencia-de-seguros">www.argentina.gob.ar/superintendencia-de-seguros</Link> / 0-800-666-8400</p>
                </article>
                <section className='devCredits'>
                    <p><a target="_blank"
                        href="https://www.linkedin.com/in/miguel-raad-desarrollador-web/">Sitio web desarrollado por Miguel Raad    </a><a target="_blank" href="mailto:miguelraad2020@gmail.com"><i
                            className="bi bi-envelope-fill"></i></a><a target="_blank"
                                href="https://www.linkedin.com/in/miguel-raad-desarrollador-web/"><i className="bi bi-linkedin"></i></a><a target="_blank" href="https://github.com/miguelraad28"><i
                                    className="bi bi-github"></i></a></p>
                </section>
            </footer >
        </>
    );
}

export default Footer;
