import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./Contacto.scss";

const Contacto = () => {
    const [formData, setFormData] = useState({})

    const navigate = useNavigate();

    const sendForm = () => {
        if (!formData.name || !formData.phone || !formData.email || !formData.message) return alert('Por favor complete todos los campos')
        fetch(`${process.env.REACT_APP_BACKEND_URL}/wh/landing-contacto-form`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ...formData
            })
        }).then(res => res.json()).then(data => {
            if(data.success){
                navigate('/emailSent')
            }
        })
            .catch(err => console.log(err))
    }

    const handleOnChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    return (
        <main className='contactSectionContainer'>
            <section className='contactContainer'>
                <div className='formContactContainer'>
                    <h1>¡CONTACTANOS!</h1>
                    <h2>S&A Seguros</h2>
                    <div className='form'>
                        <label>Dirección de email</label>
                        <input required type="email" name='email' onChange={handleOnChange} />
                        <label>Número de teléfono</label>
                        <input required type="number" name='phone' onChange={handleOnChange} />
                        <label>Nombre y apellido</label>
                        <input required type="text" name='name' onChange={handleOnChange} />
                        <label>Razón de contacto</label>
                        <textarea required type="text" name='message' onChange={handleOnChange} />
                        <button onClick={sendForm}>ENVIAR</button>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default Contacto;
