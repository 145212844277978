import DesktopMenu from './DesktopMenu/DesktopMenu';
import MobileMenu from './MobileMenu/MobileMenu';
import './Navbar.scss';
import TopDataSection from '../TopDataSection/TopDataSection';
import { Link } from 'react-router-dom';
const Navbar = () => {
  return (
    <>
      <TopDataSection />
      <nav>
        <div className='navMobileWrapper'>
          <MobileMenu />
          <div className="navbarLogoContainer">
            <Link to="/"><img src="/images/logo.png" alt="S&A Seguros" /></Link>
          </div>
        </div>
        <div className='navDesktopWrapper'>
          <DesktopMenu />
        </div>

      </nav>
    </>
  );
};

export default Navbar;