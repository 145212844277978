import React from 'react';
import "./FloatingIcons.scss";
const FloatingIcons = () => {
    return (
        <div className="floatingIcons">
            <a target="_blank" href="https://www.instagram.com/sya_seguros/">
                <div className="floatingInstagramLogo">
                    <img src="/images/instagramLogo.png" />
                    <div className="floatingInstagramBackground">
                        <p className="montserrat">@sya_seguros</p>
                    </div>
                </div>
            </a>
            {/* <a target="_blank" href="https://wa.me/541121612198">
                <div className="floatingWhatsappLogo">
                    <img src="/images/squareWhatsappLogo.png" />
                    <div className="floatingWhatsappBackground">
                        <p className="montserrat">11 2161-2198</p>
                    </div>
                </div>
            </a> */}
        </div>
    );
}

export default FloatingIcons;
