import React from 'react';
import { Link } from 'react-router-dom';
import "./Nosotros.scss";
const Nosotros = () => {
    const aseguradoras = [
        {
            image: "/images/sancorSeguros.png",
            alt: "sancorSeguros"
        },
        {
            image: "/images/federacionPatronalSeguros.png",
            alt: "federacionPatronalSeguros"
        },
        {
            image: "/images/galenoSeguros.png",
            alt: "galenoSeguros"
        },
        {
            image: "/images/expertaSeguros.png",
            alt: "expertaSeguros"
        },
        {
            image: "/images/provinciaSeguros.png",
            alt: "provinciaSeguros"
        },
        {
            image: "/images/laSegundaSeguros.png",
            alt: "laSegundaSeguros"
        },
        {
            image: "/images/nacionSeguros.png",
            alt: "nacionSeguros"
        },
        {
            image: "/images/rioUruguaySeguros.png",
            alt: "rioUruguaySeguros"
        },
        {
            image: "/images/tutelarSeguros.png",
            alt: "tutelarSeguros"
        },
        {
            image: "/images/integritySeguros.png",
            alt: "integritySeguros"
        },
        {
            image: "/images/galenoART.png",
            alt: "galenoART"
        },
        {
            image: "/images/expertaART.png",
            alt: "expertaART"
        },
        {
            image: "/images/provinciaART.png",
            alt: "provinciaART"
        },
        {
            image: "/images/mercantilSeguros.png",
            alt: "mercantilSeguros",
        },
        {
            image: "/images/andinaART.png",
            alt: "andinaART",
        },
        {
            image: "/images/atmSeguros.png",
            alt: "atmSeguros",
        },
        {
            image: "/images/nobleSeguros.png",
            alt: "nobleSeguros",
        },
        {
            image: "/images/zurichSeguros.png",
            alt: "zurichSeguros",
        },
    ]
    return (
        <>
            <main className='nosotrosSectionContainer'>
                <header className='nosotrosBannerContainer'>
                    <div>
                        <img src="/images/logo.png" alt="S&A Seguros" />
                    </div>
                    <h1>Protegemos tu patrimonio, cuidamos tu economía</h1>
                </header>
                <section className='nosotrosContainer'>

                    <h2>SOBRE NOSOTROS</h2>
                    <h3>S&A Seguros</h3>
                    <div className='nosotrosInfoContainer'>
                        <p>
                            Somos un <span>broker</span> de seguros que brinda un <span>servicio integral</span> a sus clientes,
                            acompañandolos en cada etapa de crecimiento
                            de sus negocios. <span>Asesoramiento y atención
                                personalizada</span>. Buscamos las mejores opciones
                            del mercado en cobertura y costos, como así
                            también en cada servicio que le ofrecemos.
                        </p>
                        <article className='nosotrosServiciosContainer'>
                            <Link to="/servicios/recursos-humanos" data-aos="fade-up" ><img alt="RecursosHumanos" src="/images/rrhh.png" /></Link>
                            <Link to="/servicios/higiene-y-seguridad" data-aos="fade-up" data-aos-delay="200" ><img alt="HigieneYSeguridad" src="/images/hys.png" /></Link>
                            <Link to="/servicios/consultoria-medica" data-aos="fade-up" data-aos-delay="400" ><img alt="ConsultoríaMédica" src="/images/cm.png" /></Link>

                        </article>
                        <article className='middleNosotrosText'>
                            <p>REPRESENTAMOS A LAS MEJORES ASEGURADORAS DEL PAÍS</p>
                            <p>Protegemos tu patrimonio, cuidamos tu economía.</p>
                        </article>
                        <article className='nosotrosSegurosContainer'>
                            {aseguradoras.map((elm) => {

                                return <img data-aos="flip-left" src={elm.image} alt={elm.alt} />
                            })}
                        </article>
                    </div>
                </section>
            </main>
        </>
    );
}

export default Nosotros;
