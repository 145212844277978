import React from 'react';
import "./Servicios.scss";
const ConsultoriaMedica = () => {

    return (
        <section className='serviciosSectionContainer'>
            <div className='serviciosContainer'>
                <h1>ELEGINOS Y TENÉ A DISPOSICIÓN TODOS NUESTROS SERVICIOS</h1>
                <h2>S&A Seguros</h2>
                <div className='serviciosInfoContainer'>
                    <div className='serviciosInfo' id="consultoriaMedica">
                        <img src="/images/cm.png" />
                        <div className='servicioInfo'>
                            <p>Diseñamos el servicio en base a sus necesidades, por abono o prestación. Contamos con profesionales con gran experiencia en cada especialidad.</p>
                            <p>
                                Contáctenos para mayor información y presupuesto.
                            </p>
                            <h3 className='subtitle'>Brindamos un servicio integral a empresas que abarca:</h3>
                            <ul>
                                <li>Médico clínico en planta</li>
                                <li>Psicólogo en planta</li>
                                <li>Control de certificados por ausentismo</li>
                                <li>Preocupacionales de ingreso y egreso En los principales puntos del País</li>
                                <li>Psicotécnico completos según actividad a desempeñar</li>
                                <li>Auditoría Psicológica</li>
                                <li>Auditoría Traumatológica</li>
                                <li>Auditoría Médica</li>
                                <li>Auditoría Psiquiátrica</li>
                                <li>Juntas médicas</li>
                                <li>Certificados para empresas de seguridad (CABA-PSA-PROVINCIA)</li>
                                <li>Médico a domicilio</li>
                                <li>Psicotécnicos para particulares</li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ConsultoriaMedica;
