import React from 'react';
import "./Seguros.scss";
const Seguros = () => {
    return (
        <section className='segurosSectionContainer'>
            <div className='segurosContainer'>
                <div className='segurosTitle'>
                    <h1>SEGUROS QUE OFRECEMOS</h1>
                    <h2>S&A Seguros</h2>
                </div>
                <div className='segurosGridContainer'>
                    <div className='seguroContainer'>
                        <div className='seguroTitle'>
                            <h2>Personas</h2>
                            <h3>Seguros que ofrecemos</h3>
                        </div>
                        <div className='seguroInfo'>
                            <ul>
                                <li>Auto</li>
                                <li>Moto</li>
                                <li>Hogar</li>
                                <li>Accidentes personales</li>
                                <li>Vida</li>
                                <li>Caución de alquiler</li>
                                <li>Mala praxis</li>
                            </ul>
                        </div>
                    </div>
                    <div className='seguroContainer'>
                        <div className='seguroTitle'>

                            <h2>Empresas</h2>
                            <h3>Seguros que ofrecemos</h3>
                        </div>
                        <div className='seguroInfo'>
                            <ul>
                                <li>Flotas</li>
                                <li>Moto</li>
                                <li>Integral de comercio</li>
                                <li>Accidentes personales</li>
                                <li>Vida Obligatorio</li>
                                <li>Vida Colectivo</li>
                                <li>Ley Contrato de Trabajo</li>
                                <li>Caución</li>
                                <li>Integral de Consorcio</li>
                                <li>Todo riesgo operativo</li>
                                <li>Responsabilidad civil</li>
                                <li>Transporte</li>
                            </ul>
                        </div>

                    </div>
                    <div className='seguroContainer'>
                        <div className='seguroTitle'>

                            <h2>ART</h2>
                            <h3>Conseguimos tu ART ideal</h3>
                        </div>
                        <div className='seguroInfo'>
                            Brindamos un servicio diferencial en ART, buscando
                            el costo más bajo para su empresa. Realizamos
                            seguimiento y control de accidentes, capacitaciones
                            y asesoramiento para reducir la siniestralidad.
                        </div>
                    </div>

                </div>
                <div className='segurosContactButton'>
                    <button>¡CONTÁCTANOS!</button>
                </div>
            </div>
        </section>
    );
}

export default Seguros;
