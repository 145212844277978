import React from 'react';
import "./NotFound.scss";
import { Link } from 'react-router-dom';
const NotFound = () => {
    return (
        <div className='notFoundContainer'>
            <h1>Esta página no existe</h1>
            <Link to="/"><button>VOLVER AL INICIO</button></Link>
        </div>
    );
}

export default NotFound;
