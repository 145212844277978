import React from 'react';
import "./Novedad.scss"
const Novedad = (props) => {
    return (
        <article className="novedad-container">
            <h2 className="titulo-novedad">{props.title}</h2>
            <div className="descripcion-novedad">
                <p>{props.description}</p>
                <div className="fecha-container-novedad">
                    <p>Fecha de publicación:</p><p className="fecha-novedad">{props.date}</p>
                </div>
            </div>
            <div className="footer-novedad">
                <div className="footer-container">
                    <img src="/images/logo.png" alt="S&A Seguros" />
                    <ul>
                        <li>
                            <a href="tel:+5491121612198"><i className="bi bi-telephone-fill"></i></a>
                        </li>
                        <li>
                            <a href="mailto:contacto@sya-seguros.com.ar"><i className="bi bi-envelope-fill"></i></a>
                        </li>
                        <li>
                            <a target="_blank" href="https://www.instagram.com/sya_seguros/"><i className="bi bi-instagram"></i></a>
                        </li>
                        <li>
                            <a target="_blank" href="https://www.facebook.com/profile.php?id=100094506547321"><i className="bi bi-facebook"></i></a>
                        </li>
                    </ul>
                </div>
                <div className='footerContactInfo'>
                    
                </div>
            </div>
        </article>
    );
}

export default Novedad;
