import React from 'react';
import "./Novedades.scss";
import Novedad from "./Novedad/Novedad.jsx"
import { Link } from 'react-router-dom';
const Novedades = () => {

    const [expandImage, setExpandImage] = React.useState('')

    const openImage = (path) => {
        setExpandImage(path)
    }
    const handleCloseImage = () => {
        setExpandImage('')
    }
    const novedades = [
        {
            id: 1,
            title: "Actualización automática mensual valor Fondo Fiduciario de Enfermedades Profesionales.",
            description: <p>Les informamos que la Superintendencia de Riesgos del Trabajo, a través de la Disposición SRT 10/2023 de fecha 17 de octubre de 2023, ha dispuesto la actualización de la suma fija destinada al financiamiento del Fondo Fiduciario de Enfermedades Profesionales <b>(FFEP)</b>. En consecuencia, la misma será de pesos trescientos cuarenta y dos <b>($ 342,00)</b>, porempleado y se abonará a partir del periodo de cobertura Noviembre/2023 <b>(es decir, Declaración Jurada F931 de Octubre/2023)</b>.</p>,
            date: "17/10/2023"
        },
        {
            id: 2,
            title: "Nuevo portal de clientes S&A Seguros.",
            description: <p>Estimado cliente, queremos informarles de una nueva herramienta que pondremos a su disposición a partir del próximo mes <b>(Febrero 2024)</b>. Por ser cliente de S&A SEGUROS, tendrá acceso a través de nuestro portal de clientes <b><Link to='https://clientes.sya-seguros.com.ar'>https://clientes.sya-seguros.com.ar</Link></b> a todas sus pólizas contratadas, tarjetas de circulación, y podrá informarnos de un siniestro por la misma plataforma. Todos los archivos podrán ser descargados en PDF en su PC o su celular, teniendo la información al instante y en cualquier lugar donde se encuentre. <br />En los próximos días, estará recibiendo un correo electrónico dándole la bienvenida consu usuario y clave para el acceso al portal de clientes. Día a día nos esforzamos para brindar a nuestros clientes una mejor calidad en atención y servicio. Gracias por seguir confiando en nosotros.</p>,
            date: "26/01/2024"
        },
        {
            id: 3,
            title: "Nos complace anunciar la inclusión de las siguientes compañías de seguros en nuestra red de socios.",
            description: <><p>Estamos emocionados de poder ofrecerte estas nuevas opciones y estamos seguros de que encontrarás la solución perfecta para tus necesidades de seguro en nuestras nuevas compañías asociadas.<br /><br />
                <b>ATM Seguros, Noble Seguros, Zurich Seguros y Mercantil Andina Seguros</b>
                <div className="imagenesNovedad">
                    <img style={{ width: "100px" }} src='images/atmSeguros.png' /><img style={{ width: "100px", marginRight: "10px" }} src='images/nobleSeguros.png' /><img style={{ width: "100px", marginRight: "10px" }} src='images/zurichSeguros.png' /><img style={{ width: "100px", marginRight: "10px" }} src='images/mercantilSeguros.png' />
                </div>
            </p></>,
            date: "04/03/2024"
        },
        {
            id: 4,
            title: "Conocé la verdad del Dengue!",
            description: <><p>Obtén información crucial sobre prevención, síntomas y tratamiento para proteger a tu familia. En nuestro broker de seguros<br /><br />
                <b>Consultoría Médica.</b>
                <div className="imagenesNovedad">
                    <img style={{ width: "32%", marginRight: "10px", cursor: "pointer" }} src='images/dengue1.png' onClick={() => openImage('images/dengue1.png')} /><img style={{ width: "32%", marginRight: "10px", cursor: "pointer" }} src='images/dengue2.png' onClick={() => openImage('images/dengue2.png')} /><img style={{ width: "32%", marginRight: "10px", cursor: "pointer" }} src='images/dengue3.png' onClick={() => openImage('images/dengue3.png')} />
                </div>
            </p></>,
            date: "22/03/2024"
        },
        {
            id: 5,
            title: "Actualización del Fondo Fiduciario de Enfermedades Profesionales",
            description: <><p>Les informamos que la Superintendencia de Riesgos del Trabajo, a través de la Disposición SRT 3/2024 de fecha 26 de marzo de 2024, ha dispuesto la actualización de la suma fija destinada al financiamiento del Fondo Fiduciario de Enfermedades Profesionales (FFEP). En consecuencia, la misma será de pesos quinientos cincuenta y dos ($552,00), por empleado y se abonará a partir del periodo de cobertura Abril/2024 <b>(es decir, Declaración Jurada F931 de Marzo/2024)</b>. Ante cualquier duda o consulta, quedamos a disposición.<br /><br />
            </p></>,
            date: "27/03/2024"
        },
        {
            id: 6,
            title: "Actualización sobre el servicio de asistencia vehicular",
            description: <><p>Te informamos que la Superintendencia de Seguros de la Nación (SSN) decidió dejar sin efecto la <b>Resolución 217/2024</b>, que modificaba el alcance del servicio de asistencia vehicular, remolque, traslado y estadía. Esto significa que no habrá cambios en el servicio.<br />Continuaremos brindando asistencia vehicular a través de nuestra red de prestadores externos, según el alcance detallado en tu póliza. Queremos recordarte que los tiempos de espera pueden variar en función de la demanda del servicio.

                <br /><br />
            </p></>,
            date: "19/06/2024"
        }
    ]
    return (
        <section className='novedadesSectionContainer'>
            <div className='novedadesContainer'>
                <h1>NOVEDADES DEL MUNDO DE SEGUROS</h1>
                <h2>S&A Seguros</h2>
                <div class="novedades-container">
                    {expandImage &&
                        <div className="expandedImageWrapper" onClick={handleCloseImage}>
                            <div className="expandedImageContainer">
                                <img src={expandImage} />
                            </div>
                        </div>}
                    {novedades.reverse().map(novedad => <Novedad key={novedad.id} {...novedad} />)}
                </div>
            </div>
        </section>
    );
}

export default Novedades;
