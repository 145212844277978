import React from 'react';
import { Link } from 'react-router-dom';
import "./EmailSent.scss";
const EmailSent = () => {
    return (
        <section className='emailSentSectionContainer'>
            <div className='emailSentContainer'>
                <h1>¡Gracias por contactarnos!</h1>
                <p>
                    Agradecemos su interés en nuestros servicios, nos pondremos en contacto con usted a la brevedad.
                </p>
                <Link to="/"><button>VOLVER AL INICIO</button></Link>
            </div>
            <img src="" />
        </section>
    );
}

export default EmailSent;
