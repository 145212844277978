import React from 'react';

const HigieneYSeguridad = () => {
    return (
        <section className='serviciosSectionContainer'>
            <div className='serviciosContainer'>
                <h1>ELEGINOS Y TENÉ A DISPOSICIÓN TODOS NUESTROS SERVICIOS</h1>
                <h2>S&A Seguros</h2>
                <div className='serviciosInfoContainer'>
                    <div className='serviciosInfo' id="higieneYSeguridad">
                        <img src="/images/hys.png" />
                        <div className='servicioInfo'>
                            <p>
                                Cubrimos las necesidades de asesoramiento diseñando un plan a medida de cada cliente. Contamos con profesionales de trayectoria, capacitados para dar solución a cada problemática que se presente.
                            </p>
                            <h3>¿Qué ofrece nuestra rama de higiene y seguridad?</h3>
                            <h4>HIGIENE Y SEGURIDAD EN EL TRABAJO</h4>
                            <ul>
                                <li>Representación técnica antes distintos organismos de control y ART.</li>
                                <li>Asesoramiento para el cumplimiento de los requisitos legales vigentes.</li>
                                <li>Auditorias de Evaluación y diagnóstico de condiciones de trabajo.</li>
                                <li>Asesoramiento para adecuar las instalaciones y métodos de trabajo a los requeridos por la legislación vigente y que sean los más seguros y eficaces.</li>
                                <li>Provisión de señalización y pictogramas de Seguridad y prevención.</li>
                                <li>Confección y provisión de procedimientos e instructivos de Seguridad y prevención de accidentes y/o enfermedades laborales, de acuerdo a los riesgos generales y </li>específicos de las actividades de la Empresa.
                                <li>Programación y desarrollo de Capacitaciones y entrenamientos del personal, en todos los temas relacionados a trabajo seguro y prevención.</li>
                                <li>Confección de documentación exigida por la legislación vigente.</li>
                                <li>Investigación y seguimiento de accidentes y/o enfermedades laborales.</li>
                                <li>Mantenimiento de estadísticas de siniestralidad.</li>
                                <li>Asesoramiento sobre las especificaciones técnicas y usos de elementos de protección personal.</li>

                            </ul>
                            <h4>SERVICIO DE GESTION AMBIENTAL</h4>
                            <ul>
                                <li>Asesoramiento y confección de la documentación requerida y adecuada a cada empresa.</li>
                                <li>Capacitación y entrenamiento del personal.</li>
                                <li>Realización de Auditorías internas de seguimiento y evaluación.</li>
                                <li>Mantenimiento de un sistema de mejora continua del sistema.</li>
                                <li>Mantenimiento de matriz legal y de aspectos e impactos ambientales.</li>
                                <li>Confección y desarrollo de Estudios de impacto ambiental.</li>
                                <li>Realización de auditorías ambientales.</li>
                                <li>Realización de trámites ambientales ante los organizamos de Control (ACUMAR, INA, SAyDS, OPDS, ADA, etc)</li>
                            </ul>
                            <h4>MEDICIONES</h4>
                            <ul>
                                <li>Iluminación.</li>
                                <li>Ruido en ambiente de trabajo y al vecindario.</li>
                                <li>Resistencia de Puesta a tierra y continuidad eléctrica.</li>
                                <li>Funcionamiento de Disyuntor diferencial.</li>
                                <li>Carga térmica.</li>
                                <li>Vibraciones.</li>
                                <li>Toma de muestra y análisis de agua, por laboratorio habilitado.</li>
                                <li>Contaminantes ambientales y particulados.</li>
                                <li>Carga de fuego.</li>
                                <li>Ventilación.</li>
                                <li>Ergonómicos de los puestos de trabajo.</li>
                                <li>Planes de Evacuación y emergencias con los planos y presentación ante Defensa Civil.</li>
                                <li>Programas de seguridad para obras y montajes.</li>
                                <li>Planes de trabajo seguro.</li>
                            </ul>
                            <h4>CAPACITACIONES</h4>
                            <ul>
                                <li>RCP</li>
                                <li>DEA</li>
                                <li>PRIMEROS AUXILIOS</li>
                                <li>MANEJO DE EXTINTORES</li>
                                <li>DERRAME DE SUSTANCIAS PELIGROSAS</li>
                                <li>SIMULACRO DE INCENDIO</li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HigieneYSeguridad;
