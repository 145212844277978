import React from 'react';

const RecursosHumanos = () => {
    return (
        <section className='serviciosSectionContainer'>
            <div className='serviciosContainer'>
                <h1>ELEGINOS Y TENÉ A DISPOSICIÓN TODOS NUESTROS SERVICIOS</h1>
                <h2>S&A Seguros</h2>
                <div className='serviciosInfoContainer'>
                    <div className='serviciosInfo' id="recursosHumanos">
                        <img src="/images/rrhh.png" />
                        <div className='servicioInfo'>
                            <p>
                                Este servicio apunta a la búsqueda de personal para todos los puestos y actividades de las empresas y Pymes.
                            </p>
                            <p>
                                Cabe destacar que el mismo se realiza por abono y no por persona como realizan la mayoría de las consultoras de RRHH, esto le permite al cliente realizar varias búsquedas optimizando costos (sin pagar un salario por cada postulante)
                            </p>
                            <h3>El servicio consta de las siguientes etapas:</h3>
                            <ul>
                                <li>Búsqueda y recepción de CV en base a los requerimientos del cliente.</li>
                                <li>Filtro de los CV recibidos ya sea por distancia, requisitos para el puesto dados por el cliente.</li>
                                <li>Filtro psicotécnico de los postulantes que reúnen los requisitos de ingreso</li>
                                <li>Los postulantes APTOS se envían al cliente junto con los informes de los psicotécnicos para la entrevista final con el representante que se designe.</li>

                            </ul>
                            <p className='servicioClarification'>
                                Es importante destacar que los psicotécnicos que realizamos son utilizando diferentes técnicas que apuntan a evaluar en cada postulante ansiedad, depresión, personalidad y aptitudes para el puesto a cubrir.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
}

export default RecursosHumanos;
